import { h } from "preact";

import { reviews } from "./reviews.json"; 

import "./reviews-app-styles.scss";

export function ReviewsApp() {
    if (!Array.isArray(reviews) || !reviews.length) {
        console.log('reviews list is empty');
        return null;
    }

    function starIcon(index, number) {
        if (number >= index) {
            return (
                <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M2.79666 13.0391C3.13651 13.3027 3.56424 13.2207 4.02127 12.8867L6.96268 10.7246L9.90408 12.8867C10.367 13.2207 10.7888 13.3027 11.1346 13.0391C11.4744 12.7812 11.5447 12.3535 11.3631 11.8262L10.1971 8.375L13.1678 6.24805C13.6248 5.91992 13.8299 5.53906 13.6892 5.12891C13.5545 4.71875 13.1678 4.52539 12.6053 4.53125L8.97244 4.56055L7.86502 1.08008C7.68924 0.541016 7.39041 0.236328 6.96268 0.236328C6.5408 0.236328 6.24197 0.541016 6.06619 1.08008L4.95877 4.56055L1.3201 4.53125C0.757599 4.52539 0.37674 4.71875 0.241974 5.12305C0.0954896 5.53906 0.300568 5.91992 0.763458 6.24805L3.7283 8.375L2.56815 11.8262C2.38651 12.3535 2.45682 12.7812 2.79666 13.0391Z" fill="#FFDA1B"/>
                </svg>
                )
        } else {
            return (
                <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M2.60135 13.0391C2.94119 13.3027 3.36893 13.2207 3.82596 12.8867L6.76736 10.7246L9.70877 12.8867C10.1717 13.2207 10.5935 13.3027 10.9392 13.0391C11.2791 12.7812 11.3494 12.3535 11.1678 11.8262L10.0017 8.375L12.9724 6.24805C13.4295 5.91992 13.6346 5.53906 13.4939 5.12891C13.3592 4.71875 12.9724 4.52539 12.4099 4.53125L8.77713 4.56055L7.66971 1.08008C7.49393 0.541016 7.1951 0.236328 6.76736 0.236328C6.34549 0.236328 6.04666 0.541016 5.87088 1.08008L4.76346 4.56055L1.12479 4.53125C0.562286 4.52539 0.181427 4.71875 0.0466615 5.12305C-0.0998229 5.53906 0.105255 5.91992 0.568146 6.24805L3.53299 8.375L2.37283 11.8262C2.19119 12.3535 2.26151 12.7812 2.60135 13.0391Z" fill="#CCCCCC"/>
</svg>
            )
        }
    }

    return <div className="reviews--list">{reviews.map(({ content, photo_url, author, origin }) => <article className="review--item">
        {/* <div className="review--item__stars">
            {starIcon(1, stars)}
            {starIcon(2, stars)}
            {starIcon(3, stars)}
            {starIcon(4, stars)}
            {starIcon(5, stars)}
        </div> */}
        <img src={photo_url} alt={author} />
        <p>"{content}"</p>
        <strong className="review--item__author">{author}</strong>
        <span className="review--item__origin">via {origin}</span>
    </article>)}</div>;
}