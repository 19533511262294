{
    "reviews": [
        {
            "stars": 5,
            "content": "O ChefsClub me permitiu ir a lugares que eu nunca pensaria em ir se não fosse o incentivo do desconto. Mas, chegando aos restaurantes, nos agradamos com algo muito além do preço. Adoro quando vejo que tem novidade. Recomendo a todos os amigos!",
            "author": "Soraya Sales",
            "origin": "App Store",
            "photo_url": "https://s3-sa-east-1.amazonaws.com/chefs-salles/depoimentos/thumb-soraya.png"
        },
        {
            "stars": 4,
            "content": "O que mais gosto do ChefsClub, além do desconto, é a oportunidade de conhecer novos restaurantes. Virei cliente fiel de vários. Recomendo a todos os conhecidos.",
            "author": "Diogo Ramires",
            "origin": "Google Play",
            "photo_url": "https://s3-sa-east-1.amazonaws.com/chefs-salles/depoimentos/thumb-diogo.png"
        },
        {
            "stars": 5,
            "content": "É um sistema muito bacana que, além de oferecer benefícios, nos estimula a sair da zona de conforto e conhecer novos lugares, novas culinárias, novos sabores. Acho sensacional. 😉",
            "author": "Arícia Figueiredo",
            "origin": "Twitter",
            "photo_url": "https://s3-sa-east-1.amazonaws.com/chefs-salles/depoimentos/thumb-aricia.png"
        }
    ]
}